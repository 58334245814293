"use client";

import React from "react";
import gsap from "gsap";
import Logo from "@components/common/Icons/Logo";
// const Logo = styled.div`
//   .circle {
//     width: ${(props: any) => (props.radius ? `${props.radius}px` : "40px")};
//     height: ${(props: any) => (props.radius ? `${props.radius}px` : "40px")};
//     border-radius: 50%;
//     border: ${(props: any) =>
//         props.borderWidth ? `${props.borderWidth}px` : "8px"}
//       solid #bbb;
//     &.left {
//       transform: translateX(
//         ${(props: any) =>
//           props.borderWidth ? `${round(props.borderWidth / 2)}px` : "4px"}
//       );
//     }
//     &.right {
//       transform: translateX(
//         ${(props: any) =>
//           props.borderWidth ? `-${round(props.borderWidth / 2)}px` : "-4px"}
//       );
//     }
//   }
// `;
const View = ({ className, ...props }: any) => {
  const ref: any = React.useRef();
  React.useEffect(() => {
    gsap.to(ref.current, {
      rotation: "+=90",
    });
  }, []);
  return (
    <div>
      <Logo className={className} style={{ maxWidth: "240px" }} />
      {/* {gstyles.icons({ name: "ehphuong" })} */}
    </div>
    // <Logo/>
    // <Logo className="flex" {...props}>
    //   <div className="circle left" ref={ref}></div>
    //   <div className="circle right"></div>
    // </Logo>
  );
};
export default View;
