"use client";

import { useRouter } from "next/navigation";
import qs from "qs";
import routeStore from "@utils/routeStore";
import {
  map,
  merge,
  isUndefined,
  unset,
  get,
  isPlainObject,
  assign,
} from "lodash";
import { getCurrentLocaleConfig } from "@translations/util";
interface LocalRoute {
  location: Location | null;
  navigateExternal: (url: string) => void;
  redirectUrl: (params: any, options: any) => string;
  pushStateParams: (params: any) => void;
  push: (params: any) => void;
  toUrl: (ruleName: string, value?: any) => string;
  breadcrumbs: (ruleName: string, value?: any) => any;
  locale?: string;
  // push: () => any;
}
interface HookUseNextRoute {
  (): LocalRoute;
  getParams: () => object;
  mergeParams: (currParams?: any, params?: any) => any;
}
const mergeParams = (currParams: any, params: any) => {
  const rtn = merge(currParams, params);
  // unset
  map(params, (val, key) => {
    if (isUndefined(val)) {
      unset(rtn, key);
    }
  });
  return rtn;
};
const getWindow = () => {
  if (typeof window !== "undefined") return window;
  return {};
};
const getParams = (location: Location | object) => {
  return {
    ...qs.parse(`${get(location, "search") || ""}`.slice(1)),
    ...(() => {
      const state = get(location, "state", {});
      if (isPlainObject(state)) return state;
      return {};
    })(),
    ...get(getWindow(), "pageContext.params", {}),
  };
};
const useLocalRoute = (): LocalRoute => {
  // push, back, reload,
  const router = useRouter();
  const result: LocalRoute = {
    ...router,
    redirectUrl: (params?: any, options?: any): string => {
      const origin = get(options, "origin", true) ? location.origin : "";
      let { search, hash } = location;
      if (params) {
        const currParams = qs.parse(`${search}`.slice(1));
        const finalParams = mergeParams(currParams, params);
        search = `${qs.stringify(finalParams)}`;
        search = search ? `?${search}` : "";
      }
      return `${origin}${location.pathname}${search}${hash || ""}`;
    },
    pushStateParams: (params) => {
      let { search, hash } = location;
      if (params) {
        search = `${qs.stringify(params)}`;
        search = search ? `?${search}` : "";
      }
      const url = `${location.pathname}${search}${hash || ""}`;
      if (typeof window !== "undefined") {
        window.history.pushState(`${search}`, "", url);
      }
    },
    // define default function
    location: typeof window !== "undefined" ? window.location : null,
    navigateExternal: (url: string): void => {
      if (typeof window !== "undefined") {
        window.location.href = url;
      }
    },
    toUrl: (ruleName: string, params?: object) => {
      let url = routeStore.toUrl(ruleName, params);
      // if (typeof window !== "undefined" && !!ruleName) {
      //   const location = window.location;
      //   const localeInstance = getCurrentLocaleConfig(location);
      //   url = localeInstance.withLangPrefix(url);
      //   return url;
      // }
      return url;
    },
    breadcrumbs: routeStore.breadcrumbs,
  };
  return result;
};
const useNextRoute: HookUseNextRoute = assign(useLocalRoute, {
  getParams: () => {
    const location: Location | object =
      typeof window === "undefined" ? {} : window.location;
    return getParams(location);
  },
  mergeParams,
});
export default useNextRoute;
