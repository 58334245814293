export const NAVS = [
  {
    title: "Trang Chủ",
    link: "/",
  },
  {
    title: "Chơi Đồ",
    link: "/san-pham",
  },
  {
    title: "Học Tập",
    link: "#",
    children: [
      {
        title: "React Native",
        link: "/hoc-tap/rn/gioi-thieu",
      },
    ],
  },
  {
    title: "Về Tôi",
    link: "/me",
  },
  // {
  //   title: "CV",
  //   link: "https://ehphuong.page.link/cv",
  //   target: "_blank",
  // },
];
