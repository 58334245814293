import { get, isEmpty, isFunction } from "lodash";
interface TypeRouteStore {
  breadcrumbs: (ruleName: string, value?: any) => any;
  addRule: (ruleName: string, value: any) => void;
  toUrl: (ruleName: string, params?: object) => string;
  //   parseUrl: (slug: string) => any;
}
interface TypeGetRouteStore {
  (): TypeRouteStore;
}
export const helper = {
  url: (ruleName: string, params?: any) => {
    const result = {
      url: `/${ruleName}`,
      breadcrumbs: get(params, "breadcrumbs") || [],
    };
    if (isEmpty(ruleName)) return result;
    if (!!get(params, "slug")) {
      result.url = `/${ruleName}/${get(params, "slug")}`;
      if (!!get(params, "lesson")) {
        result.url = `/${ruleName}/${get(params, "slug")}/${get(
          params,
          "lesson"
        )}`;
      }
    }
    if (!!get(params, "id")) {
      result.url = `/${ruleName}/${get(params, "id")}`;
    }
    return result;
  },
};
const getRouteStore: TypeGetRouteStore = () => {
  const store: { [key: string]: any } = {};
  const routeStore = {
    addRule: (ruleName: string, config: object) => {
      store[ruleName] = config;
    },
    toUrl: (ruleName: string, params?: object) => {
      const rule = get(store, ruleName);
      if (rule && isFunction(rule.url)) {
        return get(rule.url(params), "url");
      }
      throw Error(`Rulename '${ruleName}' is not found`);
    },
    breadcrumbs: (ruleName: string, params?: object) => {
      const rule = get(store, ruleName);
      if (rule && isFunction(rule.url)) {
        return get(rule.url(params), "breadcrumbs");
      }
      throw Error(`Breadcrumb '${ruleName}' is not found`);
    },
  };
  return routeStore;
};

export const routeStore = getRouteStore();

export default routeStore;
