"use client";
import React, { createContext, useContext, ReactNode } from "react";
import {
  AppRouteContext,
  AppRouteProps,
  TypeRouterContext,
} from "@type/provider/Route";
import useRoute from "@hooks/useNextRoute";
import routeStore, { helper } from "@utils/routeStore";
import { first } from "lodash";
export const RouteContext = createContext<AppRouteContext>({
  router: {
    push: () => {},
  },
});

interface TypeUseRouteContext {
  (): AppRouteContext;
}

// create rule for every routing;
routeStore.addRule("login", {
  url: (params?: object) => {
    return helper.url("login", params);
  },
});
routeStore.addRule("clock", {
  url: (params?: object) => {
    return helper.url("clock");
  },
});
routeStore.addRule("baiViet", {
  url: (params?: object) => {
    return helper.url("bai-viet", {
      ...params,
    });
  },
});
routeStore.addRule("hocTap", {
  url: (params?: object) => {
    return helper.url("hoc-tap", {
      ...params,
    });
  },
});
routeStore.addRule("sanPham", {
  url: (params?: object) => {
    return helper.url("san-pham", {
      ...params,
    });
  },
});
routeStore.addRule("me", {
  url: (params?: object) => {
    return helper.url("me", {
      ...params,
    });
  },
});
routeStore.addRule("posts", {
  url: (params?: object) => {
    return helper.url("posts", {
      ...params,
    });
  },
});
routeStore.addRule("tag", {
  url: (params?: object) => {
    return helper.url("tag", {
      ...params,
    });
  },
});
routeStore.addRule("styleguide", {
  url: (params?: object) => {
    return helper.url("styleguide", {
      ...params,
    });
  },
});
routeStore.addRule("home", {
  url: (params: object) => {
    return helper.url("", params);
  },
});
routeStore.addRule("facebook", {
  url: (params: object) => {
    return {
      url: "https://www.facebook.com/ehphuong",
    };
  },
});
routeStore.addRule("instagram", {
  url: (params: object) => {
    return {
      url: "https://www.instagram.com/ehphuong/",
    };
  },
});
routeStore.addRule("github", {
  url: (params: object) => {
    return {
      url: "https://github.com/silverit",
    };
  },
});

const RouteProvider = ({ children, ...props }: AppRouteProps): ReactNode => {
  // define all route's method to use;
  const nextRouter = useRoute();
  const router: TypeRouterContext = {
    ...nextRouter,
    push: (path: string, params?: object) => {
      path = first(path) === "/" ? `${path}`.slice(1) : `${path}`;
      const baseUrl = nextRouter.toUrl(path, params);
      if (!!baseUrl) {
        nextRouter.push(baseUrl);
      }
    },
  };
  return (
    <RouteContext.Provider value={{ router }}>{children}</RouteContext.Provider>
  );
};
export const useRouteContext: TypeUseRouteContext = () => {
  return useContext(RouteContext);
};
export default RouteProvider;
