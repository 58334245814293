"use client";
import React from "react";
import { useLocomotiveContext } from "@providers/LocomotiveProvider";
import Logo from "@components/common/Logo";
import dynamic from "next/dynamic";
import Link from "@components/common/Link";
// import Modal from "@components/Modal";
import { map, get } from "lodash";
import cx from "classnames";
import styles from "./Header.module.scss";
import { NAVS } from "@utils/constants";

const View = ({ type = "", distance = false, isHidden = true }: any) => {
  const { scroll } = useLocomotiveContext();
  const Swicher = dynamic(() => import("./Switcher"));
  const ref = React.useRef({ static: true, hidden: false });
  const [showModal, $showModal] = React.useState(false);

  React.useEffect(() => {
    if (scroll) {
      const banner: any = document.getElementById("banner");
      const header: any = document.getElementById("header");
      const header1: any = distance
        ? document.getElementById("header1")
        : false;
      scroll.on("scroll", (instance: any) => {
        let bannerHeight = banner ? banner.getBoundingClientRect().height : 400;
        let headerHeight = header.getBoundingClientRect().height;
        // light dark (style header)
        if (!type) {
          if (instance.direction === "down" && ref.current.static) {
            if (instance.scroll.y > bannerHeight - headerHeight) {
              header.classList.add(styles.switched);
              header1?.classList?.add(styles.switched);

              ref.current.static = false;
            }
          }
          if (instance.direction === "up" && !ref.current.static) {
            if (instance.scroll.y <= bannerHeight - headerHeight) {
              header1?.classList?.remove(styles.switched);
              header.classList.remove(styles.switched);

              ref.current.static = true;
            }
          }
        }
        // hidden
        if (instance.direction === "down" && !ref.current.hidden) {
          if (
            (bannerHeight > 400 && instance.scroll.y > bannerHeight) ||
            instance.scroll.y > bannerHeight - 200
          ) {
            // header.classList.remove(styles.switched);
            header1?.classList?.add(styles.hidden);
            header.classList.add(styles.hidden);
            ref.current.hidden = true;
          }
        }
        if (instance.direction === "up" && ref.current.hidden) {
          header1?.classList?.remove(styles.hidden);
          header.classList.remove(styles.hidden);
          // header.classList.add(styles.switched);
          ref.current.hidden = false;
        }
      });
    }
  }, [scroll]);
  const handler = {
    show: () => {
      $showModal(true);
    },
    hide: () => {
      $showModal(false);
    },
    toggle: () => {
      $showModal(!showModal);
    },
    stopScroll: () => {
      const _html = document.getElementsByTagName("html")[0];
      _html.style.overflow = "hidden";
    },
    startScroll: () => {
      const _html = document.getElementsByTagName("html")[0];
      _html.style.overflow = "initial";
    },
  };

  React.useEffect(() => {
    if (scroll) {
      showModal ? handler.stopScroll() : handler.startScroll();
    }
  }, [showModal]);
  return (
    <>
      <div className="fixed z-50 w-screen">
        <header
          className={cx(styles.header, { [styles.switched]: type === "white" })}
          id="header"
        >
          <div className="inner">
            {/* { write me header html which have three elements html on left, center and right. Don't use attributes of locomotive */}
            <div className={styles.headerMain}>
              <div className={styles.left}>
                <Link href={"/"} className="no-underline">
                  <Logo className={styles.logo} />
                </Link>
              </div>
              <div className={styles.center}>
                {/* <Link href={"/"} className="no-underline">
                  <Logo className={styles.logo} />
                </Link> */}
              </div>
              <div className={styles.right}>
                {map(NAVS, (nav: any, index: number) => {
                  const hasChild = !!get(nav, "children");
                  return (
                    <div className={"pv-nav"} key={`nav_${index}`}>
                      <Link
                        href={nav.link}
                        className={cx("pv-label", styles.nav)}
                        target={nav.target}
                      >
                        {nav.title}
                      </Link>
                      {hasChild && (
                        <div className="pv-child-nav ">
                          {map(get(nav, "children"), (child, j) => (
                            <Link
                              key={`nav_${index}_${j}`}
                              className="px-4 text-white"
                              href={child.link}
                            >
                              {child.title}
                            </Link>
                          ))}
                        </div>
                      )}
                    </div>
                  );
                })}
                <Swicher />
                {/* <div className={styles.menuBox}>
                  <MenuHamburger
                    className={styles.menu}
                    onClick={handler.toggle}
                    show={showModal}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </header>
      </div>
      {!!distance && (
        <div
          data-scroll-section
          className={cx(styles.header1)}
          id="header1"
        ></div>
      )}
    </>
  );
};
export default View;
